import React from 'react';
import BikeShareItem from '../components/BikeShareItem'

class BikeShare extends React.Component {
    state = {
        bikeitems: [
        ]
    }
    componentDidMount() {
        this.getData();
        setInterval(() => {
            this.getData()
        }, 5 * 60 * 1000);
    }
    getData() {
        fetch('https://api.biotron.io/travelboard/bikeshare')
            .then(res => {
                return res.json()
            })
            .then(data => {
                this.setState({ bikeitems: data })
            })
            .catch(err => {
                this.setState({ error: 'Unable to get travel times' })
            })
    }
    render() {
        const items = this.state.bikeitems.map((item, index) => {
            return (<BikeShareItem index={index} key={item.id} item={item}></BikeShareItem>)
        })
        return (
            <div>
                <h2 className="box-heading">Bike share</h2>
                {items}
            </div>

        )
    }
}

export default BikeShare