import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

// export default ({index, item}) => {
//     return (
//         <ListGroup.Item key={index} className="travel-time-item">
//             <div className="dot green"> • </div>
//             <div className="name">{item.destination}</div>
//             <div className="time green">{item.duration}</div>
//         </ListGroup.Item>
//     )
// }

class TravelTimeItem extends React.Component {
    render() {
        let dotColor = 'dot';
        let timeColor = 'time';
        let duration = parseInt(this.props.item.duration)
        if (duration > 15) {
            dotColor += ' red';
            timeColor += ' red';
        } else if (duration > 10) {
            dotColor += ' orange';
            timeColor += ' orange';
        } else {
            dotColor += ' green';
            timeColor += ' green';
        }
        return (
            <ListGroup.Item key={this.props.index} className="travel-time-item">
                <div className={dotColor}> • </div>
                <div className="name">{this.props.item.destination}</div>
                <div className={timeColor}>{this.props.item.duration}</div>
            </ListGroup.Item>
        )
    }
}

export default TravelTimeItem