import React from 'react';
import ReactAnimatedWeather from 'react-animated-weather'
import Col from 'react-bootstrap/Col'

const defaults = {
    icon: 'CLEAR_DAY',
    color: '#223e75',
    size: 64,
    animate: false
};

const mapToIcons = (state) => {
    switch (state) {
        case 'clear-day': return 'CLEAR_DAY';
        case 'clear-night': return 'CLEAR_NIGHT';
        case 'partly-cloudy-day': return 'PARTLY_CLOUDY_DAY';
        case 'partly-cloudy-night': return 'PARTLY_CLOUDY_NIGHT';
        case 'cloudy': return 'CLOUDY';
        case 'rain': return 'RAIN';
        case 'sleet': return 'SLEET';
        case 'snow': return 'SNOW';
        case 'wind': return 'WIND';
        case 'fog': return 'FOG';
        default:
            return ''
    }
}


export default ({item, hour, temperature}) => {
    return (
        <Col md={true} xs={4} className="weather-column">
            <div className="text">
                <div className="hour">{hour}</div>
                <div className="temperature">{temperature}&deg;</div>
            </div>
            <div className="icon">
                <ReactAnimatedWeather
                    icon={mapToIcons(item.icon)}
                    color={defaults.color}
                    size={defaults.size}
                    animate={defaults.animate}
                />
            </div>
        </Col>
    )
}