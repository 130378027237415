import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import TraveTimeItem from './TravelTimeItem';

class TransferTimes extends React.Component {
    state = {
        durations: [],
        error: ''
    }
    componentDidMount() {
        this.getData();
        setInterval(() => {
            this.getData()
        }, 5 * 60 * 1000);
    }
    getData() {
        fetch('https://api.biotron.io/travelboard/traveltimes')
            .then(res => res.json())
            .then(data => {
                this.setState({ durations: data })
            })
            .catch(err => {
                this.setState({error: 'Unable to get travel times'})
            })
    }
    render() {
        const items = this.state.durations.map((item, index) => {
            return (<TraveTimeItem index={index} item={item} />)
        })
        if(this.state.error === '') {
            return (
                <div>
                    <h2 className="box-heading">Travel times</h2>
                    <div className="box list-box">
                        <Card style={{ width: '100%' }}>
                            <ListGroup variant="flush">
                                {items}
                            </ListGroup>
                        </Card>
                    </div>
                </div>
    
            )
        }
        return (
            <div>
                <h2 className="box-heading">Travel Times</h2>
                <div className="box list-box">Not available</div>
            </div>
        )
        
    }
}




export default TransferTimes