import React from 'react';
import Traffic from './components/Traffic'
import BikeShare from './components/BikeShare'
import TravelTimes from './components/TravelTimes'
import PublicTransport from './components/PublicTransport'
import Weather from './components/Weather'
import Time from './components/Time'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';

const logo = require("../src/assets/biotron_logo.svg");

export default () => {
  return (
    <div>
      <Navbar expand="dark">
        <Container>
          <Navbar.Brand href="#home">
            <img className="logo" src={logo} alt="Biotron"/>
          </Navbar.Brand>
          <Form inline>
            <Time />
          </Form>
        </Container>
      </Navbar>
      <Container>
        <Row className="main-row">
          <Col xl={7} lg={7} md={6}><Traffic /></Col>
          <Col xl={5} lg={5} md={6}><TravelTimes /></Col>
        </Row>
        <Row className="main-row">
          <Col xl={7} lg={7} md={6}><PublicTransport /></Col>
          <Col xl={5} lg={5} md={6}><BikeShare /></Col>
        </Row>
      </Container>
      <div className="bg-weather">
        <Container>
          <Row className="main-row">
            <Col>
              <Weather />
            </Col>
          </Row>
        </Container>
      </div>
     
    </div>
  )
};