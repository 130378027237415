import React from 'react';
import moment from 'moment';

class Time extends React.Component {
    state = {
        displaytime: {
            day: '',
            time: '',

        }
    }
    componentDidMount() {
        this.getData();
        setInterval(() => {
            this.getData()
        }, 1* 1000);
    }
    getData() {
        const now = Date.now(); 
        const day = moment(now).format('MMM D, YYYY')
        const time = moment(now).format('h:mm')
        this.setState({ displaytime: {day, time} })
    }
    render() {
        return (
            <div>
                <div className="today">TODAY</div>
                {this.state.displaytime.day} <strong>{this.state.displaytime.time}</strong>
            </div>
        )
    }
}
export default Time