import React from 'react';
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ text }) => <div>{text}</div>;


class SimpleMap extends React.Component {
  static defaultProps = {
    center: {
      lat: 48.140264,
      lng: 17.121141
    },
    zoom: 13
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div >
        <h2 className="box-heading">Traffic info</h2>
        <div className="box" style={{ height: '57.5vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCR-7xF3oDmpwNKGQwfZ2En9e8rI9tFLh0' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            layerTypes={['TrafficLayer']}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default SimpleMap;