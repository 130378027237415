import React from 'react';

class BikeShareItem extends React.Component {
    render() {
        let classes = 'box bike-share-box green';
        if (this.props.item.available === 0) {
            classes += ' red';
        } else if (this.props.item.available < 5) {
            classes += ' orange';
        }
        return (
            <div key={this.props.index} className={classes}>
                <h3 className="heading">{this.props.item.name}</h3>
                <div className="text">Available bikes</div>
                <div className="availability">{this.props.item.available}/{this.props.item.capacity}</div>
            </div>
        )
    }
}

export default BikeShareItem