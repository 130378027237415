import React from 'react';
import Iframe from 'react-iframe'




export default () => {
    return (
        <div>
            <h2 className="box-heading">Public transportation</h2>
            <div className="box">
                <Iframe url="https://m.imhd.sk/ba/online-zastavkova-tabula?skin=1"
                    allow="geolocation"
                    width="100%"
                    height="506px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative" />
            </div>
            
        </div>
    )
}