import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import WeatherItem from './WeatherItem'

const PERIOD_5_HOURS = 4 * 60 * 60 * 1000
const MAXIMUM_ITEMS = 5

class Weather extends React.Component {
    state = {
        weather: {
        }
    }
    componentDidMount() {
        this.fetchData();
        setInterval(() => {
            this.fetchData()
        }, 15 * 60 * 1000);
    }
    fetchData() {
        fetch('https://api.biotron.io/travelboard/weather')
            .then(res => res.json())
            .then(data => {
                this.setState({ weather: data })
            })
            .catch(err => {
                this.setState({ error: 'Unable to get travel times' })
            })
    }
    render() {
        if (this.state.weather.hourly === undefined) {
            return (
                <div>No Data</div>
            )
        }
        const hourlyItems = this.state.weather.hourly.data.filter(item => {
            return (item.time * 1000) < (Date.now() + PERIOD_5_HOURS)
        })
        const hours = hourlyItems.map(item => {
            const hourString = moment(item.time * 1000).format("H:mm");
            return { hour: hourString, ...item }
        })

        const hourly = hours.map((item, index) => {
            const temperature = (item.temperature).toFixed(0);
            return (
                <WeatherItem key={index} item={item} hour={item.hour} temperature={temperature} />
            )
        })

        const daily = this.state.weather.daily.data.map((item, index) => {
            const hour = moment(item.time * 1000).format('ddd');
            const temperature = (item.temperatureHigh).toFixed(0);
            return (
                <WeatherItem key={index} item={item} hour={hour} temperature={temperature} />
            )
        }).filter((item, index) => index < MAXIMUM_ITEMS)
        return (
            <div>
                <h2 className="weather-heading">Weather</h2>
                <Row className="weather-box">
                    <Col><h4 className="weather-heading">Today</h4></Col>
                    {hourly}
                </Row>
                <Row className="weather-box">
                    <Col><h4 className="weather-heading">Forecast</h4></Col>
                    {daily}
                </Row>
            </div>
        )
    }
}
export default Weather